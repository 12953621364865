<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: ["data", "title", "options", "height"],
  computed: {
    chartData: function() {
      return this.data;
    },
  },
  mounted() {
    this.renderPieChart();
    // alert(1)
  },
  watch: {
    data: function() {
      this.$data._chart.destroy();
      this.renderPieChart();
    },
  },
  methods: {
    renderPieChart: function() {
      this.renderChart(
        {
          datasets: [
            {
              backgroundColor: ["green", "red"],
              data: this.data["datasets"],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(133, 70, 242, 0.2)",
                "rgba(133, 70, 24, 0.2)",
                "rgba(133, 127, 24, 0.2)",
                "rgba(201, 127, 24, 0.2)",
                "rgba(37, 127, 24, 0.2)",
                "rgba(37, 127, 106, 0.2)",
                "rgba(37, 8, 106, 0.2)",
                "rgba(65, 116, 213, 0.2)",
                "rgba(65, 255, 60, 0.2)",
                "rgba(0, 227, 255, 0.2)",
                "rgba(31, 12, 255, 0.2)",
                "rgba(215, 182, 33, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(133, 70, 242, 1)",
                "rgba(133, 70, 24, 1)",
                "rgba(133, 127, 24, 1)",
                "rgba(201, 127, 24, 1)",
                "rgba(37, 127, 24, 1)",
                "rgba(37, 127, 106, 1)",
                "rgba(37, 8, 106, 1)",
                "rgba(65, 116, 213, 1)",
                "rgba(65, 255, 60, 1)",
                "rgba(0, 227, 255, 1)",
                "rgba(31, 12, 255, 1)",
                "rgba(215, 182, 33, 1)",
              ],
              borderWidth: 1,
            },
          ],
          labels: this.data["labels"],
        },
        {
          title: {
            display: true,
            text: this.title,
          },
          options: {
            legend: {
              display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
          },
        }
      );
    },
  },
};
</script>
